import React, { useEffect, useState } from "react";
import { DashboardLayout, DeletePopupModal, PopupModal } from "../Components";
import { SettingNavbarComponent } from "../Components/navbar/settingsnav";
import { MdOutlineEdit } from "react-icons/md"
import { FormParser } from "../Components";
import Masters from "../Components/masters.json";
import { FileManager } from "../Components/FileManager";
import { Modal } from 'react-bootstrap';
import axiosConfig from "../Service/axiosConfig";
import { MarketingNavbarComponent } from "../Components/navbar";

const WelcomeMessage = () => {
    const MastersModal = Masters.WelcomeMessage;
    const [readOnly, setReadOnly] = useState(true);
    const [show, setShow] = useState(false);
    const [Error, SetError] = useState("");
    const [popupType, setpopupType] = useState("");
    const [paymentGateway, setPaymentGateway] = useState([])
    const [message, setMessage] = useState("");
    const handleShow = () => {
        setShow(true)
    }

    const GetPaymentGateway = async () => {
        try {
            const responce = await axiosConfig.get(`/message_framework/welcome_mesaasge/`)
            console.log(responce)
            if (responce.data.results.length > 0) {
                setPaymentGateway(responce.data.results[0])
            }
        }
        catch (error) {
            console.log(error)
        }

    }


    const formSubmit = async (Data) => {
        console.log(Data)
        try {
            if (Data.id) {
                const response = await axiosConfig.patch(`/message_framework/welcome_mesaasge/${Data.id}/`, Data)
                console.log("updated payment gateway successfully : ", response)
                setMessage("updated  successfully")
            }else{
                const response = await axiosConfig.post(`/message_framework/welcome_mesaasge/`, Data)
                console.log("created payment gateway successfully : ", response)
                setMessage("created successfully")
            }
            setpopupType("success")
        } catch (error) {
            console.log("Error setting the site data : ", error)
            setpopupType("error")
        }
    }

    useEffect(() => {
        GetPaymentGateway();
    }, [])

    return (
        <>
            <div className="child-sidenav">
                <MarketingNavbarComponent />
                <div className="childnav-content">
                    <FormParser
                        modelObject={MastersModal}
                        buttonTitle="Save"
                        readOnly={readOnly}
                        setReadOnly={setReadOnly}
                        formData={paymentGateway}
                        formSubmit={formSubmit}
                        error={Error}
                    />
                    {popupType != "" &&
                        <PopupModal popupType={popupType} Navigation="" Message={message} />
                    }
                    <Modal
                        size="xl"
                        show={show}
                        onHide={() => setShow(false)}
                        aria-labelledby="example-modal-sizes-title-lg"
                        className="fm-modal"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-modal-sizes-title-lg" className="fm-modal-title">
                                Choose images
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="fm-modal-body">
                            <FileManager />
                        </Modal.Body>
                    </Modal>
                    {readOnly &&
                        <button className={`btn btn-primary d-flex align-items-center`} onClick={() => setReadOnly(false)}>
                            <div>Edit</div>
                            <MdOutlineEdit className="ms-5" />
                        </button>
                    }
                </div>
            </div>
        </>
    )
}
export { WelcomeMessage }