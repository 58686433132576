import React, { useState, useEffect } from "react";
import { NotFound } from "../../Pages";
import axiosConfig from "../../Service/axiosConfig";
import Select from "react-select";
import { MdOutlineEdit } from "react-icons/md";
import { ReactQuillComponent } from "./reactquill/reactquill";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { MdInfo } from "react-icons/md";
import { AddressModal } from "./AddressModal";
import { BsFillCloudUploadFill } from "react-icons/bs";
import { FileManager } from "../FileManager/FileManager";
import { MultiFilemanager } from "./mutlipleImages";
import RenderFields from "./RenderFields";
const FormParser = ({
  modelObject,
  formData,
  formSubmit,
  error,
  button,
  buttonTitle,
  setVendor,
  readOnly,
  LoadingButton,
}) => {
  const [Data, SetData] = useState({});
  const [file_name, setFile_name] = useState("");
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(false);
  const [dynamicOptions, SetDynamicOptions] = useState({});
  const [DataFilter, SetDataFilter] = useState([]);
  const [Option, setOption] = useState({});
  const [Error, SetError] = useState("");
  const [Nextbutton, setNextbutton] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [FieldErrors, setFieldErrors] = useState({});
  const [search, setSearch] = useState("");
  const [nextPageUrl, setNextPageUrl] = useState("");
  const [fieldObjects, setFieldObjects] = useState([]);
  const GetData = async () => {
    try {
      for (const field_obj of field_objects) {
        if (field_obj.fieldType === "dynamicdropdown") {
          const response = await axiosConfig.get(`${field_obj.options_url}`);
          const count = Math.ceil(response.data.count / 20);
          setNextPageUrl((prevOptions) => ({
            ...prevOptions,
            [field_obj.fieldName]: response.data.next,
          }));
          SetDynamicOptions((prevOptions) => ({
            ...prevOptions,
            [field_obj.fieldName]: response.data.results,
          }));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAutoSlug = (e) => {
    const { name, value } = e.target;
    const notvalidslug = value;
    const trimmedSlug = notvalidslug.trim();
    const slug = trimmedSlug.replace(/[\/\s,&]+/g, "-").toLowerCase();
    {
      formData.slug === ""
        ? SetData({ ...Data, [name]: value, slug: slug })
        : SetData({ ...Data, [name]: value });
    }
  };

  const GetMultiData = async () => {
    let data = [];
    try {
      for (const field_obj of field_objects) {
        if (field_obj.fieldType === "dynamicMultiSelectdropdown") {
          const response = await axiosConfig.get(field_obj.options_url);
          const count = Math.ceil(response.data.count / 20);
          SetDynamicOptions((prevOptions) => ({
            ...prevOptions,
            [field_obj.fieldName]: response.data.results,
          }));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSearch = (value, name) => {
    const find_data = field_objects.find((res) => res.fieldName === name);
    if (find_data?.options_url) {
      if (find_data.fieldType === "dynamicdropdown") {
        if (find_data.options_url && find_data.options_url.includes("?")) {
          axiosConfig
            .get(`${find_data.options_url}&search=${value}`)
            .then((res) => {
              setNextPageUrl((prevOptions) => ({
                ...prevOptions,
                [find_data.fieldName]: res.data.next,
              }));
              SetDynamicOptions((prevOptions) => ({
                ...prevOptions,
                [find_data.fieldName]: res.data.results,
              }));
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          axiosConfig
            .get(`${find_data.options_url}?search=${value}`)
            .then((res) => {
              setNextPageUrl((prevOptions) => ({
                ...prevOptions,
                [find_data.fieldName]: res.data.next,
              }));
              SetDynamicOptions((prevOptions) => ({
                ...prevOptions,
                [find_data.fieldName]: res.data.results,
              }));
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
      if (find_data.fieldType === "dynamicSelectdropdown") {
        if (find_data.options_url && find_data.options_url.includes("?")) {
          axiosConfig
            .get(
              `${find_data.options_url}${
                Data[find_data.renderField] ? Data[find_data.renderField] : ""
              }&search=${value}`
            )
            .then((res) => {
              setNextPageUrl((prevOptions) => ({
                ...prevOptions,
                [find_data.fieldName]: res.data.next,
              }));
              SetSelectDynamicOptions((prevOptions) => ({
                ...prevOptions,
                [find_data.fieldName]: res.data.results, // Append new data
              }));
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    }
    setSearch(value);
  };
  const handleScroll = async (fieldName, fieldType) => {
    if (!loading && nextPageUrl && nextPageUrl[fieldName]) {
      setLoading1(true);
      try {
        const product = await axiosConfig.get(
          nextPageUrl[fieldName].replace(/^http:/, "https:")
        );
        const product_data = product.data.results;

        setNextPageUrl((prevOptions) => ({
          ...prevOptions,
          [fieldName]: product.data.next,
        }));
        if (fieldType === "dynamicSelectdropdown") {
          SetSelectDynamicOptions((prevOptions) => ({
            ...prevOptions,
            [fieldName]: [
              ...(prevOptions[fieldName] || []), // Preserve existing data
              ...product_data, // Append new data
            ],
          }));
        }
        SetDynamicOptions((prevOptions) => ({
          ...prevOptions,
          [fieldName]: [
            ...(prevOptions[fieldName] || []), // Preserve existing data
            ...product_data, // Append new data
          ],
        }));
        setLoading1(false);
      } catch (error) {
        console.error(`Error fetching data for ${fieldName}:`, error);
      }
      try {
      } finally {
        setLoading1(false);
      }
    }
  };

  // const GetDynamicData = (id) => {
  //     console.log(id)
  //     let data=[]
  //     field_objects.forEach((field_obj, ind) => {
  //         if (field_obj.fieldType === "dynamicSelectdropdown") {
  //             axiosConfig.get(`${field_obj.options_url}${id}/`).then(response => {
  //                 SetSelectedOptions({ ...SelectedOptions, [field_obj.fieldName]: response.data })
  //                 SelectedOptions[field_obj.fieldName] = response.data
  //                 console.log(response.data)
  //             }).catch((error) => {
  //                 console.log(error)
  //             })
  //         }
  //     })
  // }
  const HandleRadioChange = (e, ItemName) => {
    const { name } = e.target;
    SetData({ ...Data, [name]: ItemName });
  };
  const [SelectDynamicOptions, SetSelectDynamicOptions] = useState([]);
  const SelectOptions = (id, name) => {
    field_objects.forEach((item) => {
      if (item.fieldType === "dynamicSelectdropdown") {
        if (item.renderField === name) {
          let url = `${item.options_url}${id}`;
          axiosConfig
            .get(url)
            .then((res) => {
              console.log(res.data.results);
              setNextPageUrl((prevOptions) => ({
                ...prevOptions,
                [item.fieldName]: res.data.next,
              }));
              SetSelectDynamicOptions((prevOptions) => ({
                ...prevOptions,
                [item.fieldName]: res.data.results,
              }));
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    });
  };

  const editDataRender = async (formData) => {
    field_objects.forEach(async (field_obj, idx) => {
      if (formData) {
        Data[field_obj.fieldName] = formData[field_obj.fieldName];
        SetData(formData);
        if (field_obj.fieldType === "dynamicSelectdropdown") {
          if (formData[field_obj.renderField]) {
            let url = `${field_obj.options_url}${
              formData[field_obj.renderField]
            }`;
            console.log(url);
            await axiosConfig
              .get(url)
              .then((res) => {
                SetSelectDynamicOptions((prevOptions) => ({
                  ...prevOptions,
                  [field_obj.fieldName]: res.data.results,
                }));
                const find = res.data.results.find(
                  (find) => find.id === formData[field_obj.fieldName]
                );
                setOption({
                  value: find.id,
                  label: find[field_obj.displayField],
                });
              })
              .catch((error) => {
                console.log(error);
              });
          }
        }
        if (field_obj.fieldType === "dynamicMultiSelectdropdown") {
          let data = [];
          formData &&
            formData[field_obj.fieldName] &&
            formData[field_obj.fieldName].forEach((value) => {
              const findData = dynamicOptions[field_obj.fieldName].find(
                (find) => find.id === value
              );
              data.push(findData);
            });
          setSelectedOptions(data);
        }
      }
    });
  };
  const [selectedOptions, setSelectedOptions] = useState([])
  useEffect(() => {
    setTimeout(
      function () {
        setLoading(false);
      }.bind(this),
      1000
    );
    GetData();
    GetMultiData();
    setFieldObjects(field_objects);
  }, []);
  useEffect(() => {
    if (formData) {
      editDataRender(formData);
    }
  }, [formData]);
  const [FilterData, SetFilterdata] = useState([]);
  const [Name, SetName] = useState("");
  const HandleChange = (e) => {
    const name = e.target.name;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

    if (typeof Data[name] === "boolean") {
      // Toggle the boolean value
      SetData({ ...Data, [name]: !Data[name] });
    } else {
      SetData({ ...Data, [name]: value });
    }

    validateField(name, value);
  };

  const handleSelectChange = (selectedOption, name) => {
    SetData({
      ...Data,
      [name]: selectedOption?.value || "",
    });
    if(setVendor){
      setVendor(selectedOption?.value)
    }
    fieldObjects.forEach((item) => {
      if (selectedOption?.value) {
        field_objects = fieldObjects.map((field) => {
          if (field?.displayValue === selectedOption?.value) {
            return {
              ...field,
              required: field.required ? false : true,
            };
          } else if (
            field.displayValue &&
            field.displayValue !== selectedOption?.value
          ) {
            return {
              ...field,
              required: false,
            };
          }
          return field;
        });
        setFieldObjects(field_objects)
      }
      if (item.fieldType === "dynamicSelectdropdown") {
        if (item.renderField === name && selectedOption) {
          SelectOptions(selectedOption.value, name);
        }
      }
    });
  };
  const handleMultiSelectChange = (selectedOption, Value, name) => {
    let data = [];
    selectedOption.forEach((res) => {
      data.push(res.value);
    });
    SetData({ ...Data, [name]: data });
  };
  const HandleFiles = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const name = e.target.name;
    const value = file;
    SetData({ ...Data, [name]: value });
  };
  const HandleMultiPulFiles = (e) => {
    e.preventDefault();
    const file = e.target.files;
    const name = e.target.name;
    const value = file;
    SetData({ ...Data, [name]: value });
  };
  const OnSubmit = (e) => {
    e.preventDefault();
    const requiredFieldErrors = validateRequiredFields();
    console.log(requiredFieldErrors)
    if (requiredFieldErrors) {
      setFieldErrors(requiredFieldErrors);
      return;
    }

    // Reset field-level errors
    setFieldErrors({});
    formSubmit(Data, setFieldErrors);
  };

  console.log(Data);
  const validateField = (name, value) => {
    const regex = {
      country_name: /^[a-zA-Z]+$/,
      state_name: /^[a-zA-Z]+$/,
      city_name: /^[a-zA-Z]+$/,
      pincode: /^[0-9]+$/,
      email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
      cpt_code: /^[0-9]+$/,
    };
    const isInputValid = regex[name] ? regex[name].test(value) : true;
    const fields = field_objects.find((fields) => fields.fieldName === name);
    if (!isInputValid) {
      let name = fields.fieldName;
      setFieldErrors({ [name]: `Enter a valid ${fields.label}` });
    } else {
      setFieldErrors({});
    }
  };
  let field_objects = [];
  modelObject.orderBy.forEach((order_obj, idx) => {
    modelObject.fields.forEach((field_obj, index) => {
      if (field_obj.fieldName === order_obj) {
        let flagAddClass = true;
        modelObject.layoutSpecificFieldClasses.forEach((classObj, iex) => {
          if (classObj[field_obj.fieldName]) {
            field_obj["groupClass"] = classObj[field_obj.fieldName];
            flagAddClass = false;
          }
        });
        if (modelObject.layoutClass && flagAddClass) {
          field_obj["groupClass"] = modelObject.layoutClass;
        }
        if (
          field_obj.fieldType === "group" &&
          Array.isArray(field_obj.fields)
        ) {
          // Add groupClass to each field inside 'fields' array
          field_obj.fields.forEach((field) => {
            field.groupClass = "col-md-6";
          });
        }
        field_objects.push(field_obj);
      }
    });
  });
  const validateRequiredFields = () => {
    const requiredFieldErrors = {};

    const validateFields = (fields, data) => {
      fields.forEach((fieldItem) => {
        if (fieldItem.fieldType === "group") {
          // Recursive call for nested fields in the group
          validateFields(fieldItem.fields, data);
        } else if (fieldItem.required && !data[fieldItem.fieldName]) {
          requiredFieldErrors[
            fieldItem.fieldName
          ] = `${fieldItem.label} is required`;
        }
      });
    };

    validateFields(fieldObjects, Data);

    return Object.keys(requiredFieldErrors).length > 0
      ? requiredFieldErrors
      : null;
  };

  const handleImageChange = (files, fieldName) => {
    const file = files[0];
    SetData({ ...Data, [fieldName]: file });
  };
  const handleMultiImageChange = (files, fieldName) => {
    const file = files;
    SetData((prevData) => ({
      ...prevData,
      [fieldName]: [...(prevData[fieldName] || []), ...file],
    }));
  };

  const [ismultiple, setIsMulti] = useState();
  const Handlefilemanager = (name, ismultiple) => {
    if (ismultiple) {
      setFile_name(name);
      setIsMulti(true);
      setModalShow(true);
    } else {
      setFile_name(name);
      setModalShow(true);
    }
  };
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    if (setVendor) {
      SetData((prevData) => ({
        ...prevData,
        instant: name === 'instant' ? checked : false, // If instant is checked, uncheck next_bill
        next_bill: name === 'next_bill' ? checked : false, // If next_bill is checked, uncheck instant
      }));
    } else {
      // Generic checkbox handling for other fields
      SetData((prevData) => ({
        ...prevData,
        [name]: checked,
      }));
    }
  
    if (name) {
      field_objects = fieldObjects.map((field) => {
        if (field.switch_dependency === name) {
          if (field.is_required) {
            return {
              ...field,
              required: checked,
            };
          } else {
            return {
              ...field,
              required: false,
            };
          }
        }
        return field;
      });
      setFieldObjects(field_objects);
    }
  };
  function formatDate(dateString) {
    const date = new Date(dateString);
    if (isNaN(date)) console.log("Invalid date string");

    const [year, month, day, hours, minutes] = [
      date.getFullYear(),
      String(date.getMonth() + 1).padStart(2, "0"),
      String(date.getDate()).padStart(2, "0"),
      String(date.getHours()).padStart(2, "0"),
      String(date.getMinutes()).padStart(2, "0"),
    ];

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }
  const disablePastDates = (current) => {
    const currentDate = new Date();
    if (!current) return "";
    return current < currentDate.toISOString().slice(0, 16);
  };
  const RenderGroup = (groupData, i) => {
    return (
      <div key={groupData.fieldName}>
        <h5 className="dashboard-subtitle">{groupData.label} :</h5>
        <div className="row">
          {groupData.fields.map((fieldItem) => {
            const isRequired = fieldItem.required ? "*" : "";
            const hasError =
              Error && Error.includes(`Enter a valid ${fieldItem.label}`);
            const isRequiredAndEmpty =
              fieldItem.required && !Data[fieldItem.fieldName];
            return (
              <RenderFields
                isRequired={isRequired}
                fieldItem={fieldItem}
                FieldErrors={FieldErrors}
                i={i}
                SetData={SetData}
                HandleChange={HandleChange}
                Data={Data}
                formData={formData}
                hasError={hasError}
                readOnly={readOnly}
                handleAutoSlug={handleAutoSlug}
                loading1={loading1}
                HandleFiles={HandleFiles}
                formatDate={formatDate}
                HandleRadioChange={HandleRadioChange}
                handleSelectChange={handleSelectChange}
                dynamicOptions={dynamicOptions}
                handleScroll={handleScroll}
                handleSearch={handleSearch}
                SelectDynamicOptions={SelectDynamicOptions}
                handleMultiSelectChange={handleMultiSelectChange}
                HandleMultiPulFiles={HandleMultiPulFiles}
                handleCheckboxChange={handleCheckboxChange}
                Handlefilemanager={Handlefilemanager}
                calculateMaxDateTime={disablePastDates}
              />
            );
          })}
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="container-form">
        {loading ? (
          <div className="loader-container">
            <img src={require("./images/loader2.gif")} alt="Loading..." />
          </div>
        ) : (
          <form onSubmit={OnSubmit} encType="multipart/form-data">
            <div className="Loginerror">
              <p>{error}</p>
            </div>
            <div className="row">
              {fieldObjects &&
                fieldObjects.map((fieldItem, i) => {
                  const isRequired = fieldItem.required ? "*" : "";
                  const hasError =
                    Error && Error.includes(`Enter a valid ${fieldItem.label}`);
                  const isRequiredAndEmpty =
                    fieldItem.required && !Data[fieldItem.fieldName];
                  return (
                    <RenderFields
                      isRequired={isRequired}
                      fieldItem={fieldItem}
                      FieldErrors={FieldErrors}
                      i={i}
                      SetData={SetData}
                      HandleChange={HandleChange}
                      Data={Data}
                      formData={formData}
                      hasError={hasError}
                      readOnly={readOnly}
                      handleAutoSlug={handleAutoSlug}
                      loading1={loading1}
                      HandleFiles={HandleFiles}
                      formatDate={formatDate}
                      HandleRadioChange={HandleRadioChange}
                      handleSelectChange={handleSelectChange}
                      dynamicOptions={dynamicOptions}
                      handleScroll={handleScroll}
                      handleSearch={handleSearch}
                      SelectDynamicOptions={SelectDynamicOptions}
                      handleMultiSelectChange={handleMultiSelectChange}
                      HandleMultiPulFiles={HandleMultiPulFiles}
                      handleCheckboxChange={handleCheckboxChange}
                      Handlefilemanager={Handlefilemanager}
                      RenderGroup={RenderGroup}
                      calculateMaxDateTime={disablePastDates}
                    />
                  );
                })}
            </div>
            {!readOnly && (
              <button
                type="submit"
                className={`btn btn-primary ${button ? button : ""}`}
              >
                {LoadingButton ? (
                  <img
                    className="loader-image"
                    src={require("../FormParser/images/loader.gif")}
                  />
                ) : buttonTitle ? (
                  buttonTitle
                ) : (
                  "Submit"
                )}
              </button>
            )}
            {modalShow && (
              <FileManager
                modalShow={modalShow}
                setModalShow={setModalShow}
                handleFile={
                  ismultiple ? handleMultiImageChange : handleImageChange
                }
                file_name={file_name}
                ismultiple={ismultiple ? true : false}
                value="image_key"
              />
            )}
          </form>
        )}
      </div>
    </>
  );
};
export { FormParser };
