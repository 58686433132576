import React, { useState, useEffect, useRef } from 'react';
import axiosconfig from "../Service/axiosConfig"
import { FaEllipsisV } from 'react-icons/fa';
import { Modal, Button } from 'react-bootstrap'; // Import Bootstrap components
import { FaPlus } from 'react-icons/fa';
import { PopupModal } from '../Components';
import AlertDeletePopupModal from '../Components/PopupModal/AlertDeletePopupModal';
import { DeletePopupModal } from '../Components';
import { MarketingNavbarComponent } from '../Components/navbar';
function ChatDashBoard() {
  const [formData, setFormData] = useState({ title: '', messages: [{ message: '' }] }); // Single state for form data
  const [newFolderName, setNewFolderName] = useState(''); // State for folder title
  const [newMessage, setNewMessage] = useState(''); // State for message
  const [folders, setFolders] = useState([]); // State to hold folder data
  const [parentFolder, setParentFolder] = useState(null); // For nested folders
  const [popupType, setpopupType] = useState("");
  const [message, setMessage] = useState('');
  const [error,SetError]=useState("")
  const [deletePopupType, setDeletepopupType] = useState("")
  const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("")
  const [RowId, SetRowId] = useState()
  const [bredCrumbs, setBredcrumbs] = useState([{
    id: "",
    name: "Files"
  }])
  const [selectedFolder, setSelectedFolder] = useState(null); // State to manage selected folder
  const [showOptions, setShowOptions] = useState(false); // State to control the visibility of options modal
  const optionsRef = useRef(null);
  const [showEditModal, setShowEditModal] = useState(false); // State for edit modal visibility
  const openOptionsModal = (folder) => {
    setSelectedFolder(folder);
    setShowOptions(true); // Show options modal
  };

  const closeOptionsModal = () => {
    setShowOptions(false); // Close options modal
    setSelectedFolder(null); // Clear selected folder
  };

  const handleUpdate = (folder) => {
    console.log(folder)
    setFormData({
      id: folder.id,
      title: folder.title,
      messages: folder.messages
    });
    setShowEditModal(true); // Open edit modal
  }

  const handleDelete = () => {
    // Logic for deleting the folder
    console.log(`Deleting folder: ${selectedFolder.title}`);
  };
  // Function to load and filter folders from localStorage
  const loadAndFilterFolders = async () => {
    let apiUrl;

    // Determine if the folder is a root folder or a child folder
    if (parentFolder === null) {
      // Root folders: is_root is true
      apiUrl = '/message_framework/chatoptions/?is_root=True&page_size=500';
    } else {
      // Child folders: is_root is false and filter based on parentFolder
      apiUrl = `/message_framework/chatoptions/?is_root=False&parent=${parentFolder}&page_size=500`;
    }

    try {
      // Fetch data from the API
      const response = await axiosconfig.get(apiUrl);
      const apiFolders = response.data.results; // Assume the response contains the folder data

      // Update the folders state with the fetched data
      setFolders(apiFolders);
    } catch (error) {
      console.error('Error loading folders:', error);
    }
  };

  // Call loadAndFilterFolders on component mount and when parentFolder changes
  useEffect(() => {
    loadAndFilterFolders();
  }, [parentFolder]);
  const handleDoubleClick = (id, name) => {
    handleFilterData(id, name)
    setParentFolder(id); // Set the selected folder as the parent folder
  };

  const handleFieldChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value // Update the specific field in formData
    }));
  };

  const handleMessageChange = (index, value) => {
    const updatedMessages = [...formData.messages];
    updatedMessages[index] = { message: value }; // Update the specific message in the correct format
    setFormData((prevData) => ({
      ...prevData,
      messages: updatedMessages
    }));
  };

  // Function to add a new message input field
  const addMessageField = () => {
    setFormData((prevData) => ({
      ...prevData,
      messages: [...prevData.messages, { message: '' }] // Add a new empty message object
    }));
  };
  console.log(formData)
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSaveFolder(formData.title, formData.messages); // Call save folder function
    }
  };
  const handleSaveFolder = async (folder_name, message) => {
    if (!folder_name) return; // Prevent saving empty names or messages

    const isRoot = parentFolder === null; // Determine if the folder is a root folder

    const newFolder = {
      title: folder_name, // Use the folder name from input
      value: `/${folder_name.toLowerCase().replace(/\s+/g, '_')}`, // Generate value from folder name
      parent: parentFolder || null, // Parent can be null if it's a root folder
      is_root: isRoot, // Set is_root property
      messages: message, // The message input from the textarea
      is_exit_option: false
      // message_type: "input_select" // Example property
    };
    setFormData({ title: '', messages: [{ message: '' }] })
    try {
      const response = await axiosconfig.post('/message_framework/chatoptions/', newFolder, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      // Call the loadAndFilterFolders function to update folder list
      loadAndFilterFolders();

      console.log('Folder saved to server:', response.data);
    } catch (error) {
      console.error('Error saving folder:', error);
    }
  };
  const handlePatch = async () => {
    // Format messages array to be an array of strings
    const formattedMessages = formData.messages.map(messageObj => messageObj.message);

    const newFolder = {
      chat_optionid: formData.id,
      title:formData.title,  // Assuming 'id' corresponds to 'chat_optionid'
      messages: formattedMessages,  // Array of strings
    };

    try {
      const response = await axiosconfig.patch(`/message_framework/update_chatbot_message/`, newFolder, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      // Reset the form data and close modal after successful patch
      setFormData({ title: '', messages: [{ message: '' }] });
      setMessage('Updated Successfully!')
      setpopupType("success")
      setShowEditModal(false);
      loadAndFilterFolders(); // Update folder list

      console.log('Data sent to server:', response.data);
    } catch (error) {
      console.error('Error sending data to server:', error);
    }
  };


  const handleFilterData = (id, name) => {
    setParentFolder(id)
    const newBreadcrumb = {
      id: id,
      name: name,
    };

    const breadcrumbExists = bredCrumbs.some((breadcrumb) => breadcrumb.id === newBreadcrumb.id);

    if (!breadcrumbExists) {
      setBredcrumbs((prevBreadcrumbs) => [...prevBreadcrumbs, newBreadcrumb]);
    }


  }
  console.log(bredCrumbs)
  const hanldeDrop = (is_root, id) => {
    if (is_root) {
      setParentFolder(null)
    }
    else {
      setParentFolder(id)

    }
  }

  const DeleteAlert = (id) => {
    SetRowId(id)
    SetAlertDeletePopupType("success")
}
const CancelAlerrt = () => {
    SetAlertDeletePopupType("error")
}
const HandleDelete = async (id) => {
    await axiosconfig.delete(`/message_framework/chatoptions/${id}/`).then(() => {
        setDeletepopupType("success")
        SetAlertDeletePopupType("error")
        loadAndFilterFolders()
    }).catch((error) => {
        console.log(error)
        if(error.response?.data?.error){
          SetError(error.response?.data?.error)
        }
        setDeletepopupType("error")
        SetAlertDeletePopupType("error")
    })
}
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (optionsRef.current && !optionsRef.current.contains(event.target)) {
        closeOptionsModal();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [optionsRef]);
  return (
    <>
      <div className="child-sidenav">
      <MarketingNavbarComponent />
        <div className="childnav-content" style={{ padding: "20px" }}>
          <div className='row'>
            <div className='col-12'>
              <div className='form-group'>
                <input
                  type="text"
                  placeholder="Enter your title"
                  value={formData.title}
                  onChange={handleFieldChange}
                  onKeyPress={handleKeyPress}
                  className="form-control"
                  name='title'
                  autoFocus
                />
              </div>
            </div>
            <div className='col-12'>
              {formData.messages.map((messageObj, index) => (
                <div className='form-group'>
                  <textarea
                    key={index}
                    value={messageObj.message} // Access the message property
                    onChange={(e) => handleMessageChange(index, e.target.value)}
                    className="form-control"
                    placeholder={`Message ${index + 1}`}
                    onKeyPress={handleKeyPress}
                    rows={3} // Adjust the number of rows as needed
                  />
                </div>
              ))}
              {/* Add button to add new message field */}
              <Button variant="outline-primary" onClick={addMessageField} className="mt-2">
                <FaPlus /> Add Message
              </Button>
            </div>
          </div>
          <h3>Chatbot Categories:</h3>
          <div className="breadcrumb-container">
            {
              bredCrumbs.map((breadCrumb, ind) => (
                <div key={ind}>
                  <div
                    className="breadcrumb-item"
                    onClick={() => hanldeDrop(breadCrumb.name === "Files" ? true : false, breadCrumb.id)}
                  >
                    {breadCrumb.name}
                  </div>
                </div>
              ))
            }
          </div>
          <div className="folder-cards">
            {folders.length > 0 ? (
              <div className="folder-card-list">
                {folders.map((folder, index) => (
                  <div key={folder.id} className="folder-card-item" onDoubleClick={() => handleDoubleClick(folder.id, folder.title)}>
                    <div className="folder-card" style={{ position: "relative" }}>
                      <div className="folder-card-content">

                        {/* Flex container for number and title */}
                        <div className="folder-header">
                          <h4 className="folder-order-number">{index + 1}.</h4>
                          <h4 className="folder-title">{folder.title}</h4>
                        </div>

                        <FaEllipsisV
                          className="options-icon"
                          onClick={() => openOptionsModal(folder)}
                        />
                        <p className="folder-id">(ID: {folder.id})</p>

                        {/* Message header */}
                        <h5 className="message-header">Message:</h5>

                        {/* Dynamic Message content */}
                        <p className="folder-message">
                          <ol>
                            {folder.messages?.map((res, msgIndex) => (
                              <li key={msgIndex}>{res.message}</li> // Rendering message inside <li> element
                            ))}
                          </ol>
                        </p>
                        {showOptions && selectedFolder && selectedFolder.id === folder.id && (
                          <div className="options-card" ref={optionsRef}>
                            <p onClick={() => handleUpdate(folder)}>Update</p>
                            <p onClick={()=>DeleteAlert(folder.id)}>Delete</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p>{parentFolder === null ? 'No root chatbot categories available. Please add some!' : 'No subcategories available. Select a parent folder or add a new one.'}</p>
            )}
          </div>
        </div>
      </div>
      <Modal
        show={showEditModal}
        onHide={() => {
          setFormData({ title: "", messages: [{ message: "" }] });
          setShowEditModal(false);
        }}
        size="lg" // Make the modal large
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Category:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label>Title:</label>
            <input
              type="text"
              value={formData.title}
              onChange={handleFieldChange}
              name="title"
              className="form-control"
            />
          </div>

          <div className="row">
            {formData.messages.map((messageObj, index) => {
              const columnClass = formData.messages.length > 1 ? 'col-6' : 'col-12'; // Use col-6 if more than one message, otherwise col-12
              return (
                <div key={index} className={`form-group ${columnClass}`}> {/* Dynamic column size based on message length */}
                  <textarea
                    value={messageObj.message}
                    onChange={(e) => handleMessageChange(index, e.target.value)}
                    className="form-control"
                    placeholder={`Message ${index + 1}`}
                    rows={3} // Adjust the number of rows as needed
                  />
                </div>
              );
            })}
          </div>


          {/* Add button to add new message field */}
          <Button
            variant="outline-primary"
            onClick={addMessageField}
            className="mt-2"
          >
            <FaPlus /> Add Message
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setFormData({ title: "", messages: [{ message: "" }] });
              setShowEditModal(false);
            }}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handlePatch}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      {popupType != "" &&
        <PopupModal popupType={popupType} Navigation="" Message={message} />
      }
      {deletePopupType !== "" &&
        <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} error={error}/>
      }
      {
        AlertDeletePopupType === "success" &&
        <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
      }
    </>
  );
}

export { ChatDashBoard };
